import {
  ADD_ITEM_TO_GROCERY_CART,
  REMOVE_ITEM_FROM_GROCERY_CART,
  EMPTY_GROCERY_CART,
  DISCARD_GROCERY_ITEM,
} from "../constants";

const cartReducer = (state = { groceryCartItems: [] }, action) => {


  switch (action.type) {
    case ADD_ITEM_TO_GROCERY_CART:

      const newAddItem = action['data'];
      const addingItemExist = state.groceryCartItems.find(item => item.id === newAddItem.id);
      if (!addingItemExist) {
        state.groceryCartItems.push(newAddItem)
      } else {
        addingItemExist.quantity = addingItemExist.quantity + 1;
        addingItemExist.productDetail.quantity = addingItemExist.quantity;
        // addingItemExist.productDetail = newAddItem.productDetail;
      }
      return { ...state };

    case REMOVE_ITEM_FROM_GROCERY_CART:
      const newRemoveItem = action['data'];
      const removingItemExist = state.groceryCartItems.find(item => item.id === newRemoveItem);
      if (removingItemExist.quantity === 1) {
        state.groceryCartItems = state.groceryCartItems.filter(item => item.id !== newRemoveItem)
      } else {
        removingItemExist.quantity = removingItemExist.quantity - 1;
        removingItemExist.productDetail.quantity = removingItemExist.quantity;
      }
      return { ...state };

    case DISCARD_GROCERY_ITEM:
      const newDiscardItem = action['data'];
      state.groceryCartItems = state.groceryCartItems.filter(item => item.id !== newDiscardItem)
      return { ...state };

    case EMPTY_GROCERY_CART:
      return { groceryCartItems: [] };

    default:
      return state;
  }

}

export default cartReducer;