export const USER_DATA = 'USER_DATA';
export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_IN_POPUP = 'LOGGED_IN_POPUP';
export const FORGOT_PASSWORD_POPUP = 'FORGOT_PASSWORD_POPUP';
export const REGISTER_POPUP = 'REGISTER_POPUP';
export const USER_LOCATION = 'USER_LOCATION';
export const TAXI_DATA = 'TAXI_DATA';
export const BOOKING_SUMMARY_DATA = 'BOOKING_SUMMARY_DATA';
export const ADD_ITEM_TO_FOOD_CART = 'ADD_ITEM_TO_FOOD_CART';
export const REMOVE_ITEM_FROM_FOOD_CART = 'REMOVE_ITEM_FROM_FOOD_CART';
export const DISCARD_FOOD_ITEM = 'DISCARD_FOOD_ITEM';
export const EMPTY_FOOD_CART = 'EMPTY_FOOD_CART';
export const FOOD_CART_ADDED_ID = 'FOOD_CART_ADDED_ID';
export const FOOD_CART_REMOVE_ADDED_ID = 'FOOD_CART_REMOVE_ADDED_ID';
export const ADD_ITEM_TO_GROCERY_CART = 'ADD_ITEM_TO_GROCERY_CART';
export const REMOVE_ITEM_FROM_GROCERY_CART = 'REMOVE_ITEM_FROM_GROCERY_CART';
export const EMPTY_GROCERY_CART = 'EMPTY_GROCERY_CART';
export const DISCARD_GROCERY_ITEM = 'DISCARD_GROCERY_ITEM';
export const ADD_ITEM_TO_PLUMBING_CART = 'ADD_ITEM_TO_PLUMBING_CART';
export const REMOVE_ITEM_FROM_PLUMBING_CART = 'REMOVE_ITEM_FROM_PLUMBING_CART';
export const EMPTY_PLUMBING_CART = 'EMPTY_PLUMBING_CART';
export const DISCARD_PLUMBING_ITEM = 'DISCARD_PLUMBING_ITEM';
export const SERVICE_SEGMENTS = 'SERVICE_SEGMENTS';
export const CONFIG_DATA = 'CONFIG_DATA';
export const SITE_DATA = 'SITE_DATA';
export const SEGMENT_ERR_MESSAGE = 'SEGMENT_ERR_MESSAGE';
export const GLOBAL_LOADING = 'GLOBAL_LOADING';




