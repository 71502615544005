import { ADD_ITEM_TO_FOOD_CART, DISCARD_FOOD_ITEM, EMPTY_FOOD_CART, FOOD_CART_ADDED_ID, FOOD_CART_REMOVE_ADDED_ID, REMOVE_ITEM_FROM_FOOD_CART } from "../constants";

const foodCartReducer = (state = { foodCartItems: [], foodCartAddedId: [] }, action) => {


  switch (action.type) {
    case ADD_ITEM_TO_FOOD_CART:
      const newAddItem = action['data'];
      const addingItemExist = state.foodCartItems.find(item => item.id === newAddItem.id);
      if (!addingItemExist) {
        state.foodCartItems.push(newAddItem)
      } else {
        addingItemExist.quantity = newAddItem.quantity;
        addingItemExist.productDetail.quantity = newAddItem.productDetail.quantity;
        addingItemExist.productDetail.option = newAddItem.productDetail.option || addingItemExist.productDetail.option;
      }
      return { ...state };

    case REMOVE_ITEM_FROM_FOOD_CART:
      const newRemoveItem = action['data'];
      const removingItemExist = state.foodCartItems.find(item => item.id === newRemoveItem);
      if (removingItemExist.quantity === 1) {
        state.foodCartItems = state.foodCartItems.filter(item => item.id !== newRemoveItem)
      } else {
        removingItemExist.quantity = removingItemExist.quantity - 1;
        removingItemExist.productDetail.quantity = removingItemExist.quantity;
      }
      return { ...state };

    case DISCARD_FOOD_ITEM:
      const newDiscardItem = action['data'];
      state.foodCartItems = state.foodCartItems.filter(item => item.id !== newDiscardItem)
      return { ...state };

    case EMPTY_FOOD_CART:
      return { foodCartItems: [], foodCartAddedId: [] };

    case FOOD_CART_ADDED_ID:
      const newAddedId = action['data'];
      if (state.foodCartAddedId.includes(newAddedId)) {
        state.foodCartAddedId = [...state.foodCartAddedId]
      } else {
        state.foodCartAddedId.push(newAddedId)
      }
      return { ...state };

    case FOOD_CART_REMOVE_ADDED_ID:
      const newRemoveAddedId = action['data'];
      state.foodCartAddedId = state.foodCartAddedId.filter(id => id !== newRemoveAddedId)
      return { ...state };

    // (prevState.includes(newItem.id) && [...prevState]) || [...prevState, newItem.id]
    // return { ...state, foodCartAddedId: action['data'] };

    default:
      return state;
  }

}

export default foodCartReducer;