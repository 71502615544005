import Cookies from 'js-cookie';
import config from '../_config';

const publicKey = config.TAXI_PUBLIC_KEY;
const secretKey = config.TAXI_SECRET_KEY;

export function setHeader() {
  const _headers = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      publicKey: publicKey,
      secretKey: secretKey,
      // 'Content-Type': 'multipart/form-data',
      // Accept: 'application/json',

    },
  };

  if (Cookies.get('_access_token')) {
    _headers['headers']['Authorization'] = `Bearer ${Cookies.get('_access_token')}`;
  }
  if (JSON.parse(sessionStorage.getItem("Language")) === "ka") {
    _headers['headers']['locale'] = "ka"
  }
  else{
    if (JSON.parse(sessionStorage.getItem("Language")) === "en") {
      _headers['headers']['locale'] = "en"
    }
  }
  // if (Cookies.get('_access_token')) {
  //   _headers['headers']['Authorization'] = `Bearer ${Cookies.get('_access_token')}`;
  // } else {
  //   _headers['headers']['publicKey'] = publicKey
  //   _headers['headers']['secretKey'] = secretKey
  // }

  return _headers;
}