const config = {
  BASE_API_URL: process.env.NEXT_PUBLIC_BASE_API_URL,
  TAXI_PUBLIC_KEY: process.env.NEXT_PUBLIC_TAXI_PUBLIC_KEY,
  

  TAXI_SECRET_KEY: process.env.NEXT_PUBLIC_TAXI_SECRET_KEY,
  

};

export default config;