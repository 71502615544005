import {
  ADD_ITEM_TO_PLUMBING_CART,
  REMOVE_ITEM_FROM_PLUMBING_CART,
  EMPTY_PLUMBING_CART,
  DISCARD_PLUMBING_ITEM,
} from "../constants";

const plumbingCartReducer = (state = { plumbingCartItems: [] }, action) => {


  switch (action.type) {
    case ADD_ITEM_TO_PLUMBING_CART:
      const newAddItem = action['data'];
      const addingItemExist = state.plumbingCartItems.find(item => item.id === newAddItem.id);
      if (!addingItemExist) {
        state.plumbingCartItems.push(newAddItem)
      } else {
        addingItemExist.quantity = addingItemExist.quantity + 1;
        addingItemExist.serviceDetails.quantity = addingItemExist.quantity;
        // addingItemExist.serviceDetails = newAddItem.serviceDetails;
      }
      return { ...state };

    case REMOVE_ITEM_FROM_PLUMBING_CART:
      const newRemoveItem = action['data'];
      const removingItemExist = state.plumbingCartItems.find(item => item.id === newRemoveItem);
      if (removingItemExist.quantity === 1) {
        state.plumbingCartItems = state.plumbingCartItems.filter(item => item.id !== newRemoveItem)
      } else {
        removingItemExist.quantity = removingItemExist.quantity - 1;
        removingItemExist.serviceDetails.quantity = removingItemExist.quantity;
      }
      return { ...state };

    case DISCARD_PLUMBING_ITEM:
      const newDiscardItem = action['data'];
      state.plumbingCartItems = state.plumbingCartItems.filter(item => item.id !== newDiscardItem)
      return { ...state };

    case EMPTY_PLUMBING_CART:
      return { plumbingCartItems: [] };

    default:
      return state;
  }

}

export default plumbingCartReducer;