import { combineReducers } from 'redux';
import foodCartReducer from './foodCartReducer';
import groceryCartReducer from './groceryCartReducer'
import userData from './index';
import plumbingCartReducer from './plumbingCartReducer';
import taxiService from './taxiServiceReducer';

export default combineReducers({
  user: userData,
  taxi: taxiService,
  foodCart: foodCartReducer,
  groceryCart: groceryCartReducer,
  plumbingCart: plumbingCartReducer
});
