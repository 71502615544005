/* eslint-disable indent */
import {
  LOGGED_IN, USER_DATA, FORGOT_PASSWORD_POPUP, LOGGED_IN_POPUP, REGISTER_POPUP, USER_LOCATION, SERVICE_SEGMENTS, CONFIG_DATA, SITE_DATA, SEGMENT_ERR_MESSAGE, GLOBAL_LOADING
} from '../constants';
import Cookies from 'js-cookie';

const userData = (state = {
  userData: {}, isLoggedIn: Cookies.get('_access_token') && true || false, isLoggedInPopup: false, isForgotPasswordPopup: false,
  isRegisterPopup: false, userLocation: {}, serviceSegments: [], configData: {}, siteData: {}, segmentErrMessage: '', globalLoading: true
}, action) => {
  switch (action.type) {
    case USER_DATA:
      return { ...state, userData: action['data'] };
    case LOGGED_IN:
      return { ...state, isLoggedIn: action['data'] };
    case LOGGED_IN_POPUP:
      return { ...state, isLoggedInPopup: action['data'] };
    case REGISTER_POPUP:
      return { ...state, isRegisterPopup: action['data'] };
    case FORGOT_PASSWORD_POPUP:
      return { ...state, isForgotPasswordPopup: action['data'] };
    case USER_LOCATION:
      return { ...state, userLocation: action['data'] };
    case SERVICE_SEGMENTS:
      return { ...state, serviceSegments: action['data'] };
    case CONFIG_DATA:
      return { ...state, configData: action['data'] };
    case SITE_DATA:
      return { ...state, siteData: action['data'] };
    case SEGMENT_ERR_MESSAGE:
      return { ...state, segmentErrMessage: action['data'] };
    case GLOBAL_LOADING:
      return { ...state, globalLoading: action['data'] };
    default:
      return state;
  }
};

export default userData;
